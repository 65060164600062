import { useState, useEffect } from "react";

export const Navigation = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  var linkToBuch;
  var linkToTrailer;
  var linkToFiveSteps;
  var linkToLeitsaetze;
  var linkToHappyProdukte;
  var linkToApp;
  var linkToAbout;
  var linkToReferenz;
  var linkToContact;
  if (width > 768) {
    linkToBuch = (
      <a
        href="#buch"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
      >
        Buch
      </a>
    );
    linkToTrailer = (
      <a
        href="#trailer"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
      >
        Trailer/Medien
      </a>
    );
    linkToFiveSteps = (
      <a
        href="#fiveSteps"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
      >
        5 Schritte
      </a>
    );
    linkToLeitsaetze = (
      <a
        href="#leitsaetze"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
      >
        Leitsätze
      </a>
    );
    linkToHappyProdukte = (
      <a
        href="#happyProdukte"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
      >
        HappyProdukte
      </a>
    );
    linkToApp = (
      <a
        href="#app"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
      >
        Glück To Go
      </a>
    );
    linkToAbout = (
      <a
        href="#about"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
      >
        Über Mich
      </a>
    );
    linkToReferenz = (
      <a
        href="#referenz"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
      >
        Referenz und Presse
      </a>
    );
    linkToContact = (
      <a
        href="#contact"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
      >
        Kontakt
      </a>
    );
  } else {
    linkToBuch = (
      <a
        href="#buch"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        Buch
      </a>
    );
    linkToTrailer = (
      <a
        href="#trailer"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        Trailer
      </a>
    );
    linkToFiveSteps = (
      <a
        href="#fiveSteps"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        5 Schritte
      </a>
    );
    linkToLeitsaetze = (
      <a
        href="#leitsaetze"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        Leitsätze
      </a>
    );
    linkToHappyProdukte = (
      <a
        href="#happyProdukte"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        HappyProdukte
      </a>
    );
    linkToApp = (
      <a
        href="#app"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        Glück To Go
      </a>
    );
    linkToAbout = (
      <a
        href="#about"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        Über Mich
      </a>
    );
    linkToReferenz = (
      <a
        href="#referenz"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        Referenz und Presse
      </a>
    );
    linkToContact = (
      <a
        href="#contact"
        className="page-scroll"
        style={{ fontSize: "1.6rem", color: "white" }}
        data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1"
      >
        Kontakt
      </a>
    );
  }
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <div style={{ color: "steelblue", fontSize: "20px" }}>
              <span>Happyologe</span>
              <span style={{ verticalAlign: "super", fontSize: "15px" }}>
                ®
              </span>
            </div>
          </a>{" "}
          <div className="navbar-brand-long"></div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>{linkToBuch}</li>
            <li>{linkToTrailer}</li>
            <li>{linkToReferenz}</li>
            <li>{linkToFiveSteps}</li>
            <li>{linkToLeitsaetze}</li>
            <li>{linkToHappyProdukte}</li>
            <li>{linkToApp}</li>
            <li>{linkToAbout}</li>

            <li>{linkToContact}</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
