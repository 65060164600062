import Home from './Pages/home'
import Impressum from './Pages/impressum'
import Datenschutzerklärung from "./Pages/datenschutzerklärung";
import "./App.css";
import "font-awesome/css/font-awesome.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route
          path="/Impressum"
          target="_blank"
          rel="noreferrer noopener"
          component={Impressum}
        ></Route>
        <Route
          path="/Datenschutzerklärung"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Datenschutzerklärung />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
