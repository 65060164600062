import { Link } from "react-router-dom";


export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Kontakt</h2>
              </div>
              <div className="col-xs-12 col-md-6">
                <img src="img/Folie1.jpg" className="img-contact" alt="" />{" "}
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Kontaktinformationen</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Addresse
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefonnummer
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-mobile"></i> Mobilnummer
                </span>
                {props.data ? props.data.phone2 : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <a
                  href="mailto:patrik.wenke@happyologe.com"
                  style={{ color: "white" }}
                >
                  <span>
                    <i className="fas fa-envelope"></i> Email
                  </span>
                  {props.data ? props.data.email : "loading"}
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a
                      href={props.data ? props.data.instagram : "/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.youtube : "/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.facebook : "/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="column">
          <div className="container text-center">
            <p>&copy; 2021 Patrik Wenke</p>
          </div>
          <div className="container text-center">
            <Link to="/Impressum">Impressum</Link>
          </div>
          <div className="container text-center">
            <Link to="/Datenschutzerklärung">Datenschutzerklärung</Link>
          </div>
        </div>
      </div>
    </div>
  );
}


