export const Leitsaetze = (props) => {
  return (
    <div id="leitsaetze" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>LEITSÄTZE DER HAPPYOLOGIE</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-leitsaetze">
                  {" "}
                  <i className={d.icon}></i>
                  
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>
                      <span style={{ fontWeight: 700 }}>{d.text1}</span>
                      <span>{d.text}</span>
                    </p>
                    <p>{d.text2}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
}
