import { Link } from "react-router-dom";

export const Referenz = (props) => {
  return (
    <div id="referenz" className="text-center">
      <div className="section-title-video">
        <h2>Referenz und Presse</h2>
      </div>
      <div
        className="container"
        style={{ padding: "50px", alignItems: "center", width: "100%" }}
      >
        <div>
          <p>
            <a
              href={props.data ? props.data[7].url : "loading..."}
              target="_blank"
              rel="noreferrer"
            >
              <div className="referenz-container">
                <p>{props.data ? props.data[7].text : "loading..."}</p>
                <h1>{props.data ? props.data[7].company : "loading..."}</h1>
                <a
                  href={props.data ? props.data[7].url : "loading..."}
                  target="_blank"
                  rel="noreferrer"
                >
                  Zum Video auf sat1.de
                </a>
              </div>
            </a>
          </p>
          <p>
            <a
              href={props.data ? props.data[0].url : "loading..."}
              target="_blank"
              rel="noreferrer"
            >
              <div className="referenz-container">
                <p>{props.data ? props.data[0].text : "loading..."}</p>
                <h1>{props.data ? props.data[0].company : "loading..."}</h1>
                <a
                  href={props.data ? props.data[0].url : "loading..."}
                  target="_blank"
                  rel="noreferrer"
                >
                  Zum Video auf Youtube
                </a>
              </div>
            </a>
          </p>
          <p>
            <a
              href={props.data ? props.data[1].url : "loading..."}
              target="_blank"
              rel="noreferrer"
            >
              <div className="referenz-container">
                <p>{props.data ? props.data[1].text : "loading..."}</p>
                <h1>{props.data ? props.data[1].company : "loading..."}</h1>
                <a
                  href={props.data ? props.data[1].url : "loading..."}
                  target="_blank"
                  rel="noreferrer"
                >
                  Zum Interview auf WDR 2
                </a>
              </div>
            </a>
          </p>
          <p>
            <a
              href={props.data ? props.data[2].url : "loading..."}
              target="_blank"
              rel="noreferrer"
            >
              <div className="referenz-container">
                <p>{props.data ? props.data[2].text : "loading..."}</p>
                <h1>{props.data ? props.data[2].company : "loading..."}</h1>
                <a
                  href={props.data ? props.data[2].url : "loading..."}
                  target="_blank"
                  rel="noreferrer"
                >
                  Zum Artikel auf Spiegel.de
                </a>
              </div>
            </a>
          </p>
          <p>
            <a
              href={props.data ? props.data[8].url : "loading..."}
              target="_blank"
              rel="noreferrer"
            >
              <div className="referenz-container">
                <p>{props.data ? props.data[8].text : "loading..."}</p>
                <h1>{props.data ? props.data[8].company : "loading..."}</h1>
                <a
                  href={props.data ? props.data[8].url : "loading..."}
                  target="_blank"
                  rel="noreferrer"
                >
                  Zum Artikel auf ichev.de
                </a>
              </div>
            </a>
          </p>
          <Link
            to={props.data ? props.data[3].file : "loading..."}
            target="_blank"
            rel="noreferrer"
            download
          >
            <div className="referenz-container">
              <p>{props.data ? props.data[3].text : "loading..."}</p>
              <h1>{props.data ? props.data[3].company : "loading..."}</h1>
              <Link
                to={props.data ? props.data[3].file : "loading..."}
                target="_blank"
                rel="noreferrer"
                download
              >
                Artikel Download
              </Link>
            </div>
          </Link>
          <p>
            <Link
              to={props.data ? props.data[4].file : "loading..."}
              target="_blank"
              rel="noreferrer"
              download
            >
              <div className="referenz-container">
                <p>{props.data ? props.data[4].text : "loading..."}</p>
                <h1>{props.data ? props.data[4].company : "loading..."}</h1>
                <Link
                  to={props.data ? props.data[4].file : "loading..."}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  Artikel Download
                </Link>
              </div>
            </Link>
          </p>
          <p>
            <Link
              to={props.data ? props.data[5].file : "loading..."}
              target="_blank"
              rel="noreferrer"
              download
            >
              <div className="referenz-container">
                <p>{props.data ? props.data[5].text : "loading..."}</p>
                <h1>{props.data ? props.data[5].company : "loading..."}</h1>
                <Link
                  to={props.data ? props.data[5].file : "loading..."}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  Artikel Download
                </Link>
              </div>
            </Link>
          </p>
          <p>
            <a
              href={props.data ? props.data[6].file : "loading..."}
              target="_blank"
              rel="noreferrer"
            >
              <div className="referenz-container">
                <p>{props.data ? props.data[6].text : "loading..."}</p>
                <h1>{props.data ? props.data[6].company : "loading..."}</h1>
                <Link
                  to={props.data ? props.data[6].file : "loading..."}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  Artikel Download
                </Link>
              </div>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
