export const Buch = (props) => {
  return (
    <div id="buch" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Das Buch</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="buch-text">
              <p>{props.data ? props.data.text : "loading..."}</p>
              <p>{props.data ? props.data.text2 : "loading..."}</p>
              <p>{props.data ? props.data.text3 : "loading..."}</p>
              <p>{props.data ? props.data.text4 : "loading..."}</p>
              <p>{props.data ? props.data.text5 : "loading..."}</p>
              <p>{props.data ? props.data.text6 : "loading..."}</p>
              <p>{props.data ? props.data.text7 : "loading..."}</p>
              <p>{props.data ? props.data.text8 : "loading..."}</p>
              <p>{props.data ? props.data.text9 : "loading..."}</p>
              <p>{props.data ? props.data.text10 : "loading..."}</p>
              <p>{props.data ? props.data.text11 : "loading..."}</p>
              <p>{props.data ? props.data.text12 : "loading..."}</p>
              <p>{props.data ? props.data.text13 : "loading..."}</p>
              <p>{props.data ? props.data.text14 : "loading..."}</p>
              <p>{props.data ? props.data.text15 : "loading..."}</p>
              <p>{props.data ? props.data.text16 : "loading..."}</p>
              <p>{props.data ? props.data.text17 : "loading..."}</p>
              <p>{props.data ? props.data.text18 : "loading..."}</p>
              <p>{props.data ? props.data.text19 : "loading..."}</p>
              <p>{props.data ? props.data.text20 : "loading..."}</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/buch.PNG" className="img-responsive" alt="" />{" "}
          </div>
        </div>
        <div>
          {" "}
          <iframe
            title="buch"
            className="frameStyle"
            scrolling="no"
            frameBorder="0"
            src="//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=DE&source=ac&ref=tf_til&ad_type=product_link&tracking_id=patrikwenke-21&marketplace=amazon&amp;region=DE&placement=386980629X&asins=386980629X&linkId=f5e38753b7665ab593234fc8f4b63f4c&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
          ></iframe>{" "}
        </div>
      </div>
    </div>
  );
}


