export const FiveSteps = (props) => {
  return (
    <div id="fiveSteps">
      <div className="container">
        <div className="5steps-text">
          <h2>5 Schritte für ein GLÜCKLICHERES Leben</h2>
          <h1>
            <span>{props.data ? props.data.title : "loading..."}</span>
            <span style={{ fontWeight: 700, color: "steelblue" }}>
              {props.data ? props.data.happy : "loading..."}
            </span>
            <span>{props.data ? props.data.title2 : "loading..."}</span>
            <span style={{ fontWeight: 700, color: "steelblue" }}>
              {props.data ? props.data.happy2 : "loading..."}
            </span>
            <span>{props.data ? props.data.title3 : "loading..."}</span>
          </h1>
          <p style={{ paddingTop: "50" }}>
            <span style={{ fontWeight: 700, fontSize: 40, color: "steelblue" }}>
              {props.data ? props.data.para1 : "loading..."}
            </span>
            <span style={{ fontWeight: 700, fontSize: 30 }}>
              {props.data ? props.data.paragraph1 : "loading..."}
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 700, fontSize: 40, color: "steelblue" }}>
              {props.data ? props.data.para2 : "loading..."}
            </span>
            <span style={{ fontWeight: 700, fontSize: 30 }}>
              {props.data ? props.data.paragraph2 : "loading..."}
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 700, fontSize: 40, color: "steelblue" }}>
              {props.data ? props.data.para3 : "loading..."}
            </span>
            <span style={{ fontWeight: 700, fontSize: 30 }}>
              {props.data ? props.data.paragraph3 : "loading..."}
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 700, fontSize: 40, color: "steelblue" }}>
              {props.data ? props.data.para4 : "loading..."}
            </span>
            <span style={{ fontWeight: 700, fontSize: 30 }}>
              {props.data ? props.data.paragraph4 : "loading..."}
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 700, fontSize: 40, color: "steelblue" }}>
              {props.data ? props.data.para5 : "loading..."}
            </span>
            <span style={{ fontWeight: 700, fontSize: 30 }}>
              {props.data ? props.data.paragraph5 : "loading..."}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
