import React from "react";

export default function Datenschutz() {
  return (
    <div className="container">
      <div className="impressum-text">
        <h2 style={{ paddingTop: "20px" }}>Datenschutzerklärung</h2>
        <p>
          Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
          EU-Datenschutzgrundverordnung (DSGVO), ist:
        </p>
        <p>Patrik Wenke</p>
        <h2>Ihre Betroffenenrechte</h2>
        <p>
          Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
          können Sie jederzeit folgende Rechte ausüben:
        </p>
        <ul>
          <li>
            Auskunft über Ihre bei uns gespeicherten Daten und deren
            Verarbeitung (Art. 15 DSGVO),
          </li>
          <li>
            Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
          </li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
          <li>
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
            gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
          </li>
          <li>
            Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
            DSGVO) und
          </li>
          <li>
            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
            (Art. 20 DSGVO).
          </li>
        </ul>
        <p>
          Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
          jederzeit mit Wirkung für die Zukunft widerrufen.
        </p>
        <p>
          Sie können sich jederzeit mit einer Beschwerde an eine
          Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des
          Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche
          Stelle zuständige Behörde.
        </p>
        <p>
          Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
          mit Anschrift finden Sie unter:{" "}
          <a
            href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html
          </a>
          .
        </p>
        <p></p>
        <h2>Kontaktformular</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>
          Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
          Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer
          validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient
          der Zuordnung der Anfrage und der anschließenden Beantwortung
          derselben. Die Angabe weiterer Daten ist optional.
        </p>
        <h3>Rechtsgrundlage:</h3>
        <p>
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
          auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f
          DSGVO).
        </p>
        <p>
          Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine
          unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben
          werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
          Anschlussfragen gespeichert.
        </p>
        <p>
          Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen,
          erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten
          zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b
          DSGVO).
        </p>
        <h3>Empfänger:</h3>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <p></p>
        <h3>Speicherdauer:</h3>
        <p>
          Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage
          gelöscht.
        </p>
        <p>
          Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den
          gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach
          Ablauf dieser Fristen.{" "}
        </p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
          Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren
          Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
        </p>
        <p></p>
        <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
        <p>
          Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
          darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der
          Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
          nachfolgend „Google“) zur Darstellung von Schriften.
        </p>
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
          <a
            href="https://developers.google.com/fonts/faq"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://developers.google.com/fonts/faq
          </a>{" "}
          und in der Datenschutzerklärung von Google:{" "}
          <a
            href="https://www.google.com/policies/privacy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.google.com/policies/privacy/
          </a>
          .
        </p>
        <p></p>
        <h2>Verwendung von Typekit-Webschriftarten von Adobe Fonts</h2>
        <p>
          Wir setzen Typekit-Webschriftarten von Adobe Fonts zur visuellen
          Gestaltung unserer Website ein. Adobe Fonts ist ein Dienst der Adobe
          Systems Software Ireland Companies (4-6 Riverwalk, Citywest Business
          Campus, Dublin 24, Republic of Ireland; nachfolgend „Adobe“), der uns
          den Zugriff auf eine Schriftartenbibliothek gewährt.{" "}
        </p>
        <p>
          Zur Einbindung der von uns benutzten Schriftarten muss Ihr Browser
          eine Verbindung zu einem Server von Adobe in den USA aufbauen und die
          für unsere Website benötigte Schriftart herunterladen. Adobe erhält
          hierdurch die Information, dass von Ihrer IP-Adresse unsere Website
          aufgerufen wurde.{" "}
        </p>
        <p>
          Weitere Informationen zu Adobe Fonts finden Sie in den
          Datenschutzhinweisen von Adobe Fonts, die Sie hier abrufen können:{" "}
          <a
            href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.adobe.com/de/privacy/policies/adobe-fonts.html
          </a>
        </p>
        <p>
          Wenn Ihr Browser Web Fonts nicht unterstützt, oder Sie Ihre
          Einwilligung nicht erteilen, wird eine Standardschrift von Ihrem
          Computer genutzt.
        </p>
        <h3>Widerruf der Einwilligung:</h3>rel="noopener noreferrer"
        <p>
          Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
          Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
          eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
          wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung
          für die entsprechende Cookie-Kategorie oder alle technisch nicht
          notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie
          unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
        </p>
        <p></p>
        <h2>Eingebettete YouTube-Videos</h2>
        <p>
          Auf unserer Website betten wir YouTube-Videos ein. Betreiber der
          entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San
          Bruno, CA 94066, USA (nachfolgend „YouTube“). Die YouTube, LLC ist
          einer Tochtergesellschaft der Google LLC, 1600 Amphitheatre Pkwy,
          Mountain View, CA 94043, USA (nachfolgend „Google“). Wenn Sie eine
          Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern
          von YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten
          Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann
          YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern
          Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen.{" "}
        </p>
        <p>
          Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die
          Hinweise über das Nutzerverhalten sammeln.{" "}
        </p>
        <p>
          Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer
          Verarbeitung durch YouTube erhalten Sie in den Datenschutzerklärungen
          des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren
          diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze
          Ihrer Privatsphäre (
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy
          </a>
          ).{" "}
        </p>
        <h3>Widerruf der Einwilligung:</h3>
        <p>
          Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
          Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
          eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
          wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung
          für die entsprechende Cookie-Kategorie oder alle technisch nicht
          notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie
          unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
        </p>
        <p></p>
        <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
        <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
        <p>
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
          personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
          (Datenverarbeitung auf der Grundlage einer Interessenabwägung)
          erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
          Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
        </p>
        <p>
          Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten
          nicht mehr verarbeiten, es sei denn, wir können zwingende
          schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
          Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
          dient der Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen.
        </p>
        <h3>Empfänger eines Widerspruchs</h3>
        <p>Patrik Wenke</p>
        <h2>Änderung unserer Datenschutzbestimmungen</h2>
        <p>
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entspricht oder um
          Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
          z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
          dann die neue Datenschutzerklärung.
        </p>
        <h2>Fragen an den Datenschutzbeauftragten</h2>
        <p>
          Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
          E-Mail oder wenden Sie sich direkt an die für den Datenschutz
          verantwortliche Person in unserer Organisation:
        </p>
        <p>patrik.wenke@happyologe.com</p>
        <p>
          <em>
            Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt,
            den Experten für{" "}
            <a
              href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
              target="_blank"
              rel="noopener noreferrer"
            >
              externe Datenschutzbeauftragte
            </a>{" "}
            (Version #2020-09-30).
          </em>
        </p>
      </div>
    </div>
  );
}
