import { useState, useEffect } from "react";
import { Navigation } from "../components/navigation";
import { Header } from "../components/header";
import { Buch } from "../components/buch";
import { About } from "../components/about";
import { Leitsaetze } from "../components/leitsaetze";
import { HappyProdukte } from "../components/happyProdukte";
import { Trailer } from "../components/trailer";
import { Referenz } from "../components/referenz";
import { Contact } from "../components/contact";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import { FiveSteps } from "../components/fiveSteps";
import {App} from "../components/app";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Buch data={landingPageData.Buch} />
      <Trailer data={landingPageData.Trailer} />
      <Referenz data={landingPageData.Referenz} />
      <FiveSteps data={landingPageData.FiveSteps} />
      <Leitsaetze data={landingPageData.Leitsaetze} />
      <HappyProdukte />
      <App data={landingPageData.App} />
      <About data={landingPageData.About} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Home;