import { Link } from "react-router-dom";
export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6" style={{ paddingTop: "5%" }}>
            {" "}
            <img
              src="img/Happyologe.JPG"
              className="img-responsive"
              alt=""
            />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text" style={{ paddingTop: "5%" }}>
              <h2>Über den Autor</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center", paddingTop: "5rem" }}>
        <button
          style={{
            padding: "10px",
            borderRadius: "10px",

            backgroundColor: "#5ca9fb",
            fontSize: "18px",
          }}
        >
          <Link
            to="/files/ueber den AUTOR mit Fotos.pdf"
            target="_blank"
            rel="noreferrer"
            download
            style={{ color: "black" }}
          >
            Pdf über den Autor
          </Link>
        </button>
      </div>
    </div>
  );
};
