export function App(props) {
  return (
    <div id="app" className="text-center">
      <div className="container">
        <div className="section-title text-center">
          <h2>Die Glück To Go App</h2>
        </div>
        <center>
          <div className="row">
            <div className="col-md-HappyApp">
              {" "}
              <img
                style={{ width: "100%", padding: "10px" }}
                src="img/anleitung/1.JPG"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-md-HappyApp">
              {" "}
              <img
                style={{ width: "100%", padding: "10px" }}
                src="img/anleitung/2.JPG"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-md-HappyApp">
              {" "}
              <img
                style={{ width: "100%", padding: "10px" }}
                src="img/anleitung/3.JPG"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
          </div>
        </center>
        <center>
          <div className="row">
            <div className="col-md-HappyApp">
              {" "}
              <img
                style={{ width: "100%", padding: "10px" }}
                src="img/anleitung/4.JPG"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-md-HappyApp">
              {" "}
              <img
                style={{ width: "100%", padding: "10px" }}
                src="img/anleitung/5.JPG"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-md-HappyApp">
              {" "}
              <img
                style={{ width: "100%", padding: "10px" }}
                src="img/anleitung/6.JPG"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
          </div>
        </center>
        <center>
          <div className="row">
            <div className="col-md-HappyApp">
              {" "}
              <img
                style={{ width: "100%", padding: "10px" }}
                src="img/anleitung/7.JPG"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-md-HappyApp">
              {" "}
              <img
                style={{ width: "100%", padding: "10px" }}
                src="img/anleitung/8.JPG"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
          </div>
        </center>

        <center>
          <img
            src="img/qrCodeForHappyApp.svg"
            className="img-responsive"
            alt=""
            style={{ paddingTop: "5%", paddingBottom:"5%" }}
          />{" "}
          <a
            href={props.data ? props.data.linkApple : "loading..."}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>{props.data ? props.data.linkTextApple : "loading..."}</p>
          </a>
          <a
            href={props.data ? props.data.linkAndroid : "loading..."}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>{props.data ? props.data.linkTextAndroid : "loading..."}</p>
          </a>
        </center>
      </div>
    </div>
  );
}
