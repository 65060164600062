
export const HappyProdukte = (props) => {
  return (
    <div id="happyProdukte" className="text-center">
      <div className="container">
        <div className="section-title-happyProduct">
          <div className="text-center"></div>
          <h2>Happy Produkte</h2>
        </div>
        <iframe
          title="buch"
          className="frameStyle"
          scrolling="no"
          frameBorder="0"
          src="//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=DE&source=ac&ref=tf_til&ad_type=product_link&tracking_id=patrikwenke-21&marketplace=amazon&amp;region=DE&placement=386980629X&asins=386980629X&linkId=f5e38753b7665ab593234fc8f4b63f4c&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
        ></iframe>
        <iframe
          title="buch a3"
          className="frameStyle"
          scrolling="no"
          frameBorder="0"
          src="//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=DE&source=ac&ref=tf_til&ad_type=product_link&tracking_id=patrikwenke-21&marketplace=amazon&amp;region=DE&placement=3000228241&asins=3000228241&linkId=d743be4984470f63aa2884b5a44d2c97&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
        ></iframe>
        <iframe
          title="stadt gaertner"
          className="frameStyle"
          scrolling="no"
          frameBorder="0"
          src="//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=DE&source=ac&ref=tf_til&ad_type=product_link&tracking_id=patrikwenke-21&marketplace=amazon&amp;region=DE&placement=B07TLNF891&asins=B07TLNF891&linkId=c8b9e4829a17c1c60ea0823a071f83a9&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
        ></iframe>
        <iframe
          title="gelbe Tueten"
          className="frameStyle"
          scrolling="no"
          frameBorder="0"
          src="//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=DE&source=ac&ref=tf_til&ad_type=product_link&tracking_id=patrikwenke-21&marketplace=amazon&amp;region=DE&placement=B08Z758LFC&asins=B08Z758LFC&linkId=1e6a450325b5de77552d4546c358fbf7&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
        ></iframe>
        <iframe
          title="Glueck doch mal"
          className="frameStyle"
          scrolling="no"
          frameBorder="0"
          src="//ws-eu.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=DE&source=ac&ref=tf_til&ad_type=product_link&tracking_id=patrikwenke-21&marketplace=amazon&amp;region=DE&placement=B091WCGCZT&asins=B091WCGCZT&linkId=073d0e3a967aa50ad4f14126af6d1bfd&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=ffffff"
        ></iframe>
      </div>
      <div className="container" style={{ color: "white", paddingTop: "10px" }}>
        Bei den HappyProdukten handelt es sich um Amazon Affiliated Links
      </div>
    </div>
  );
};
