import React from "react";

export default function Impressum() {
  return (
    <div className="container">
      <div className="impressum-text">
        <h2 style={{ paddingTop: "20px" }}>Impressum</h2>
        <h2>Angaben gemäß § 5 TMG:</h2>
        <p>Patrik Wenke</p>
        <h3>Postanschrift:</h3>
        <p>Nievenheimerstr. 41 Dormagen</p>
        <h3>Kontakt:</h3>
        <p>Telefon: +49 2133 214828</p>
        <p>E-Mail: patrik.wenke@happyologe.com</p>
        <p></p>
        <h3>Vertreten durch:</h3>
        <p>Patrik Wenke</p>
        <p></p>
        <p></p>
        <p></p>
        <h2>Hinweise zur Website</h2>
        <p></p>
        <h2>Information gemäß § 36 VSBG</h2>
        <p>
          Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die
          alternative Streitbeilegung in Verbrauchersachen) erklärt der
          Betreiber dieser Website:
        </p>
        <p>
          Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p></p>
        <p>
          <em>
            Das Impressum wurde mit dem{" "}
            <a
              href="https://www.activemind.de/datenschutz/impressums-generator/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Impressums-Generator der activeMind AG
            </a>{" "}
            erstellt.
          </em>
        </p>
      </div>
    </div>
  );
}
