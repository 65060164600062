export const Trailer = (props) => {
  return (
    <div id="trailer">
      <div className="container">
        <div className="section-title text-center">
          <h2>Trailer</h2>
        </div>
        <div className="trailer-margin">
          <center>
            <h1>{props.data ? props.data.title : "loading..."}</h1>
            <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
          </center>
        </div>
        <div className="embed-responsive embed-responsive-16by9">
          <video controls style={{ zIndex: "100" }}>
            <source
              src={`${process.env.PUBLIC_URL}/videos/trailer.mp4`}
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="text-center" style={{paddingTop:"70px"}}>
          <h3>Hier gibt es mehr:</h3>
        </div>
        <div className="socials text-center">
          <a
            href="https://www.instagram.com/happyologe_official/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="instagram">
              <i
                className="fab fa-instagram"
                
              ></i>
            </div>
          </a>

          <a
            href="https://www.youtube.com/channel/UCoDGupnYQXafsOQbdvFTWIw/featured"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-youtube" style={{color:'red', fontSize:"62px"}}></i>
          </a>
        </div>
      </div>
    </div>
  );
};
