export const Header = (props) => {
  return (
    <div className="intro-fullscreen">
      <video
        src={`${process.env.PUBLIC_URL}/videos/happyPatrik.MOV`}
        autoPlay
        loop
        muted
        playsInline
      />
      <div className="intro-container">
        <div
          className="intro-text"
        >
          <h1 style={{ fontSize: "5rem" }}>
            <span style={{ color: "yellow" }}>
              {props.data ? props.data.title1 : "Loading"}
            </span>
            <span style={{ color: "white" }}>
              {props.data ? props.data.title2 : "Loading"}
            </span>
            <span
              style={{
                color: "white",
                fontSize: "3rem",
                verticalAlign: "text-top",
              }}
            >
              {props.data ? props.data.title3 : "Loading"}
            </span>
          </h1>
          <p style={{ color: "white", fontSize: "2.5rem" }}>
            {props.data ? props.data.paragraph : "Loading"}
          </p>
        </div>
      </div>
    </div>
  );
}
